import React from "react";
import { Link } from "react-router-dom";

const FooterLink = ({ to, label }) => (
  <Link
    to={to}
    className="block text-white hover:text-yellow-500 transition duration-300"
  >
    {label}
  </Link>
);

const GlobalFooter = () => {
  return (
    <footer className="bg-black text-white py-10 mt-10">
      <div className="container mx-auto px-8">
        {/* Основной контент футера */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Информация о компании */}
          <div>
            <h4 className="text-lg font-semibold mb-4 text-yellow-500">
              Company Info
            </h4>
            <FooterLink to="/about-us" label="About Us" />
            <FooterLink to="/contact" label="Contact" />
            <FooterLink to="/privacy-policy" label="Privacy Policy" />
          </div>

          {/* Решения */}
          <div>
            <h4 className="text-lg font-semibold mb-4 text-yellow-500">
              Solutions
            </h4>
            <FooterLink to="/solutions" label="Solutions" />
          </div>

          {/* Подписка на новости */}
          <div>
            <h4 className="text-lg font-semibold mb-4 text-yellow-500">
              Newsletter
            </h4>
            <p>Stay updated with our latest trends</p>
            <form className="flex mt-4">
              <input
                type="email"
                placeholder="Enter email"
                className="p-2 rounded-l bg-gray-800 text-white placeholder-gray-400 focus:outline-none"
              />
              <button className="px-4 py-2 bg-purple-700 hover:bg-yellow-500 text-white font-semibold rounded-r transition duration-300">
                Subscribe
              </button>
            </form>
          </div>
        </div>

        {/* Нижняя часть футера */}
        <div className="text-center mt-10 border-t border-gray-700 pt-6">
          <p className="text-gray-400">Designed and styled by itllte</p>
          <p className="text-gray-500 mt-1">
            &copy; {new Date().getFullYear()} itllte. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default GlobalFooter;
