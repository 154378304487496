import React from "react";

/**
 * Contact component
 * @returns {jsx}
 */
const Contact = () => {
  return (
    <div className="container mx-auto px-6 py-16 font-serif">
      {/* Заголовок страницы */}
      <h1 className="text-5xl font-extrabold text-center bg-gradient-to-r from-yellow-500 via-purple-600 to-yellow-500 text-transparent bg-clip-text mb-12">
        Contact Us
      </h1>

      {/* Приветствие и краткое описание */}
      <p className="text-lg text-center text-gray-400 mb-12">
        Whether you're interested in a partnership, need assistance, or just
        want to say hello, we're here for you. Connect with us through the form
        below or via our contact details.
      </p>

      {/* Секция с контактной информацией */}
      <div className="flex flex-col lg:flex-row lg:space-x-10 mb-16">
        {/* Офисное местоположение */}
        <div className="flex-1 bg-gray-900 p-8 rounded-lg shadow-lg">
          <h2 className="text-3xl font-semibold text-yellow-500 mb-4">
            Our Office
          </h2>
          <p className="text-lg text-gray-300 mb-4">
            <strong>itllte Headquarters</strong>
            <br />
            412 W 14 Mile Rd, Troy, MI 48083-4271, United States
          </p>
          <p className="text-lg text-gray-300">
            <strong>Phone:</strong> +1 248-585-6000
            <br />
            <strong>Email:</strong>{" "}
            <a
              href="mailto:info@itllte.com"
              className="text-yellow-500 hover:underline"
            >
              info@itllte.com
            </a>
          </p>
        </div>

        {/* Часы работы */}
        <div className="flex-1 bg-gray-900 p-8 rounded-lg shadow-lg mt-8 lg:mt-0">
          <h2 className="text-3xl font-semibold text-yellow-500 mb-4">
            Business Hours
          </h2>
          <p className="text-lg text-gray-300">
            <strong>Monday - Friday:</strong> 9:00 AM - 6:00 PM
            <br />
            <strong>Saturday:</strong> 10:00 AM - 4:00 PM
            <br />
            <strong>Sunday:</strong> Closed
          </p>
        </div>
      </div>

      {/* Форма обратной связи */}
      <div className="bg-gray-900 p-10 rounded-lg shadow-2xl">
        <h2 className="text-4xl font-extrabold text-center text-yellow-500 mb-10">
          Send Us a Message
        </h2>
        <form className="space-y-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Полное имя */}
            <div>
              <label
                htmlFor="name"
                className="block text-lg font-medium text-gray-300"
              >
                Full Name
              </label>
              <input
                type="text"
                id="name"
                className="mt-2 p-3 w-full border border-gray-700 rounded-lg focus:ring-yellow-500 bg-gray-800 text-gray-200"
                placeholder="Enter your full name"
              />
            </div>
            {/* Должность */}
            <div>
              <label
                htmlFor="position"
                className="block text-lg font-medium text-gray-300"
              >
                Position
              </label>
              <input
                type="text"
                id="position"
                className="mt-2 p-3 w-full border border-gray-700 rounded-lg focus:ring-yellow-500 bg-gray-800 text-gray-200"
                placeholder="Enter your position"
              />
            </div>
            {/* Email */}
            <div>
              <label
                htmlFor="email"
                className="block text-lg font-medium text-gray-300"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className="mt-2 p-3 w-full border border-gray-700 rounded-lg focus:ring-yellow-500 bg-gray-800 text-gray-200"
                placeholder="Enter your email address"
              />
            </div>
            {/* Телефон */}
            <div>
              <label
                htmlFor="phone"
                className="block text-lg font-medium text-gray-300"
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                className="mt-2 p-3 w-full border border-gray-700 rounded-lg focus:ring-yellow-500 bg-gray-800 text-gray-200"
                placeholder="Enter your phone number"
              />
            </div>
            {/* Название компании */}
            <div>
              <label
                htmlFor="company"
                className="block text-lg font-medium text-gray-300"
              >
                Company Name
              </label>
              <input
                type="text"
                id="company"
                className="mt-2 p-3 w-full border border-gray-700 rounded-lg focus:ring-yellow-500 bg-gray-800 text-gray-200"
                placeholder="Enter your company name"
              />
            </div>
            {/* Предпочтительный способ связи */}
            <div>
              <label
                htmlFor="contact-method"
                className="block text-lg font-medium text-gray-300"
              >
                Preferred Contact Method
              </label>
              <select
                id="contact-method"
                className="mt-2 p-3 w-full border border-gray-700 rounded-lg focus:ring-yellow-500 bg-gray-800 text-gray-200"
              >
                <option>Email</option>
                <option>Phone</option>
                <option>Other</option>
              </select>
            </div>
          </div>

          {/* Сообщение */}
          <div>
            <label
              htmlFor="message"
              className="block text-lg font-medium text-gray-300"
            >
              Your Message
            </label>
            <textarea
              id="message"
              rows="5"
              className="mt-2 p-3 w-full border border-gray-700 rounded-lg focus:ring-yellow-500 bg-gray-800 text-gray-200"
              placeholder="Enter your message"
            ></textarea>
          </div>
          {/* Кнопка отправки */}
          <div className="text-center">
            <button
              type="submit"
              className="bg-yellow-500 text-black px-8 py-3 rounded-lg text-lg font-semibold hover:bg-yellow-600 transition duration-300 shadow-lg"
            >
              Submit Message
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
