import React from "react";
import { useNavigate } from "react-router-dom";

/**
 * Pricing component
 * @returns {jsx}
 */
const Pricing = () => {
  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    navigate("/contact");
  };

  return (
    <div className="container mx-auto px-4 py-12 font-serif">
      {/* Заголовок страницы */}
      <h1 className="text-5xl font-extrabold text-center text-gradient bg-gradient-to-r from-blue-600 via-purple-600 to-blue-700 text-transparent bg-clip-text mb-16">
        Choose the Best Plan for Your Business
      </h1>

      {/* Блоки тарифов */}
      <div className="flex flex-col lg:flex-row lg:space-x-8 mb-12">
        {/* Тариф Starter */}
        <div className="flex-1 bg-slate-800 p-8 rounded-lg shadow-lg mb-8 lg:mb-0">
          <h2 className="text-3xl font-semibold text-center text-yellow-400 mb-4">
            Starter
          </h2>
          <p className="text-lg text-slate-300 text-center mb-6">
            Ideal for freelancers and small teams
          </p>
          <p className="text-4xl font-bold text-center text-yellow-400 mb-8">
            Free
          </p>
          <ul className="text-lg text-slate-300 mb-8 space-y-4">
            <li>Basic access to travel inventory</li>
            <li>Self-serve booking options</li>
            <li>24/7 support</li>
            <li>Basic reports</li>
          </ul>
          <div className="text-center">
            <button
              onClick={handleLearnMoreClick}
              className="bg-yellow-400 text-slate-800 px-6 py-3 rounded-lg text-lg font-semibold hover:bg-yellow-500 transition duration-300"
            >
              Get Started
            </button>
          </div>
        </div>

        {/* Тариф Advanced */}
        <div className="flex-1 bg-slate-800 p-8 rounded-lg shadow-lg mb-8 lg:mb-0">
          <h2 className="text-3xl font-semibold text-center text-yellow-400 mb-4">
            Advanced
          </h2>
          <p className="text-lg text-slate-300 text-center mb-6">
            Best for small to medium-sized businesses
          </p>
          <p className="text-4xl font-bold text-center text-yellow-400 mb-8">
            $49 / month
          </p>
          <ul className="text-lg text-slate-300 mb-8 space-y-4">
            <li>Extended inventory access</li>
            <li>Expense management tools</li>
            <li>Priority support</li>
            <li>Advanced reports</li>
          </ul>
          <div className="text-center">
            <button
              onClick={handleLearnMoreClick}
              className="bg-yellow-400 text-slate-800 px-6 py-3 rounded-lg text-lg font-semibold hover:bg-yellow-500 transition duration-300"
            >
              Request a Demo
            </button>
          </div>
        </div>

        {/* Тариф Pro */}
        <div className="flex-1 bg-slate-800 p-8 rounded-lg shadow-lg mb-8 lg:mb-0">
          <h2 className="text-3xl font-semibold text-center text-yellow-400 mb-4">
            Pro
          </h2>
          <p className="text-lg text-slate-300 text-center mb-6">
            For growing enterprises with up to 500 employees
          </p>
          <p className="text-4xl font-bold text-center text-yellow-400 mb-8">
            $149 / month
          </p>
          <ul className="text-lg text-slate-300 mb-8 space-y-4">
            <li>All Advanced features</li>
            <li>Custom integrations</li>
            <li>Priority customer support</li>
            <li>Detailed analytics</li>
          </ul>
          <div className="text-center">
            <button
              onClick={handleLearnMoreClick}
              className="bg-yellow-400 text-slate-800 px-6 py-3 rounded-lg text-lg font-semibold hover:bg-yellow-500 transition duration-300"
            >
              Get Started
            </button>
          </div>
        </div>

        {/* Тариф Enterprise */}
        <div className="flex-1 bg-slate-800 p-8 rounded-lg shadow-lg">
          <h2 className="text-3xl font-semibold text-center text-yellow-400 mb-4">
            Enterprise
          </h2>
          <p className="text-lg text-slate-300 text-center mb-6">
            For large organizations with custom needs
          </p>
          <p className="text-4xl font-bold text-center text-yellow-400 mb-8">
            Contact Us
          </p>
          <ul className="text-lg text-slate-300 mb-8 space-y-4">
            <li>All Pro features</li>
            <li>Dedicated Account Manager</li>
            <li>Custom solutions & integrations</li>
            <li>Full API access</li>
          </ul>
          <div className="text-center">
            <button
              onClick={handleLearnMoreClick}
              className="bg-yellow-400 text-slate-800 px-6 py-3 rounded-lg text-lg font-semibold hover:bg-yellow-500 transition duration-300"
            >
              Get a Quote
            </button>
          </div>
        </div>
      </div>

      {/* API Solutions */}
      <div className="mt-16 bg-gradient-to-r from-blue-900 to-purple-900 p-10 rounded-lg shadow-2xl">
        <h2 className="text-4xl font-extrabold text-center text-sky-400 mb-8">
          API Solutions for Seamless Integration
        </h2>
        <p className="text-lg text-slate-300 text-center mb-6">
          Leverage our API to access a vast inventory and customized rates. From
          rapid integration to extensive technical support, we offer everything
          you need to enhance your travel services.
        </p>
        <ul className="text-lg text-slate-300 space-y-4 mb-8">
          <li>Simple, fast API setup and integration</li>
          <li>Comprehensive global inventory across 220 countries</li>
          <li>24/7 technical support and dedicated account management</li>
          <li>Competitive B2B pricing and unique deals</li>
        </ul>
        <div className="text-center">
          <button
            onClick={handleLearnMoreClick}
            className="bg-sky-400 text-slate-100 px-8 py-3 rounded-lg text-lg font-semibold hover:bg-sky-500 transition duration-300"
          >
            Start API Integration
          </button>
        </div>
      </div>

      {/* Часто задаваемые вопросы */}
      <div className="mt-12 bg-slate-700 p-8 rounded-lg shadow-lg">
        <h2 className="text-4xl font-bold text-center text-sky-400 mb-8">
          Frequently Asked Questions
        </h2>
        <ul className="space-y-6">
          <li>
            <h3 className="text-xl font-semibold text-slate-200">
              What are the benefits of API integration?
            </h3>
            <p className="text-lg text-slate-300">
              Our API offers direct access to global travel inventory, with fast
              response times, custom integrations, and competitive pricing.
            </p>
          </li>
          <li>
            <h3 className="text-xl font-semibold text-slate-200">
              How do I start the integration?
            </h3>
            <p className="text-lg text-slate-300">
              Simply register and receive your free API key. Our support team
              will guide you through the process.
            </p>
          </li>
          <li>
            <h3 className="text-xl font-semibold text-slate-200">
              What if I need additional customization?
            </h3>
            <p className="text-lg text-slate-300">
              For extensive needs, our Enterprise plan provides custom solutions
              and dedicated support.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Pricing;
