import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";
import Toast from "components/ux/toast/Toast";
import { LOGIN_MESSAGES } from "utils/constants";

/**
 * Login Component
 * Renders a login form allowing users to sign in to their account.
 */
const Login = () => {
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState(false);

  const handleInputChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    // Здесь должна быть логика аутентификации
    setErrorMessage(LOGIN_MESSAGES.FAILED);
  };

  const dismissError = () => {
    setErrorMessage("");
  };

  return (
    <div className="login__form bg-black min-h-screen flex items-center justify-center px-4">
      <div className="container mx-auto p-4 flex justify-center items-center">
        <form
          onSubmit={handleLoginSubmit}
          className="w-full max-w-lg p-6 md:p-10 shadow-lg bg-gray-800 rounded-lg"
        >
          <div className="text-center mb-10">
            <h2 className="text-4xl font-extrabold text-yellow-400">
              Welcome Back
            </h2>
            <p className="text-gray-300 text-lg mt-2">
              Log in to continue to your account
            </p>
          </div>

          <div className="mb-6">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={loginData.email}
              onChange={handleInputChange}
              autoComplete="username"
              className="w-full bg-gray-700 text-gray-300 border border-gray-600 rounded-md py-3 px-4 mb-3 focus:outline-none focus:ring-2 focus:ring-yellow-400"
            />
          </div>

          <div className="mb-6">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={loginData.password}
              onChange={handleInputChange}
              autoComplete="current-password"
              className="w-full bg-gray-700 text-gray-300 border border-gray-600 rounded-md py-3 px-4 focus:outline-none focus:ring-2 focus:ring-yellow-400"
            />
          </div>

          {errorMessage && (
            <Toast
              type="error"
              message={errorMessage}
              dismissError={dismissError}
            />
          )}

          <div className="mt-4">
            <button
              type="submit"
              className="w-full bg-yellow-400 hover:bg-yellow-500 text-black font-semibold py-3 rounded-md transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-yellow-500"
            >
              Log In
            </button>
          </div>

          <div className="text-center mt-4">
            <Link
              to="/forgot-password"
              className="text-md text-yellow-400 hover:underline"
            >
              Forgot your password?
            </Link>
          </div>

          <div className="relative my-8">
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-full border-t border-gray-600"></div>
            </div>
            <div className="relative bg-gray-800 px-4 text-gray-400">
              New to itllte?
            </div>
          </div>

          <div className="text-center">
            <Link
              to="/register"
              className="text-md font-medium text-yellow-400 hover:underline"
            >
              Create an account
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
