import HeroCover from "./components/hero-cover/HeroCover";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import banner from "assests/banner.webp";
import logo1 from "assests/logo1.svg";
import logo2 from "assests/logo2.svg";
import logo3 from "assests/logo3.svg";
import logo4 from "assests/logo4.svg";
import logo5 from "assests/logo5.svg";
import logo6 from "assests/logo6.svg";
import logo7 from "assests/logo7.svg";
import logo8 from "assests/logo8.svg";
import booking from "assests/booking.avif";
import support from "assests/support.jpg";
import global from "assests/global.jpg";

const Home = () => {
  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    navigate("/contact");
  };

  const faqData = [
    {
      question: "What is itllte?",
      answer:
        "itllte is a B2B platform for travel business management, streamlining services for professionals.",
    },
    {
      question: "How do I create an account?",
      answer:
        "Click 'Login/Register' to sign up and follow the prompts to create your account.",
    },
    {
      question: "What are the benefits of joining?",
      answer:
        "itllte offers tools for managing bookings, client relationships, and analytics in one place.",
    },
    {
      question: "Do you offer API integrations?",
      answer:
        "Yes, we provide API solutions tailored to your business needs for easy integration.",
    },
    {
      question: "Can I manage bookings through the platform?",
      answer:
        "Yes, our platform allows full control over bookings, with real-time tracking and updates.",
    },
    {
      question: "Is customer support available 24/7?",
      answer:
        "Our multilingual support team is available 24/7 to assist you with any needs.",
    },
    {
      question: "Are there loyalty or reward programs?",
      answer:
        "Yes, we offer a loyalty program where you can earn points to use on bookings.",
    },
    {
      question: "Can I adjust the payment model?",
      answer:
        "Choose between Net and Gross work models, and manage payments via invoice, credit, or card.",
    },
    {
      question: "Is training or onboarding provided?",
      answer:
        "Yes, we offer onboarding assistance and resources to get you started smoothly.",
    },
    {
      question: "How can I contact itllte support?",
      answer:
        "Contact our support anytime through the platform or via our 24/7 support hotline.",
    },
  ];

  return (
    <div className="font-serif bg-black text-white">
      {/* Hero Section */}
      <div className="relative">
        <img
          src={banner}
          alt="B2B Travel Management"
          className="w-full h-[80vh] object-cover opacity-50"
        />
        <div className="absolute inset-0 bg-black bg-opacity-75 flex flex-col justify-center items-center text-center">
          <h1 className="text-5xl md:text-7xl font-extrabold text-yellow-400 mb-4">
            Simplified Travel Solutions
          </h1>
          <p className="text-xl md:text-2xl max-w-2xl mb-8">
            Optimize corporate travel with customized booking, cost management,
            and support.
          </p>
          <button
            onClick={handleLearnMoreClick}
            className="bg-yellow-500 hover:bg-yellow-600 transition-colors text-black px-8 py-4 rounded-lg font-semibold text-lg"
          >
            Learn More
          </button>
        </div>
      </div>

      {/* Partners Section */}
      <section className="py-12 bg-gray-300">
        <h2 className="text-3xl font-bold text-center mb-10 text-black-400">
          Our Trusted Partners
        </h2>
        <div className="flex flex-wrap justify-center items-center gap-6">
          <img src={logo1} alt="Client 1" className="w-20 h-20" />
          <img src={logo2} alt="Client 2" className="w-20 h-20" />
          <img src={logo3} alt="Client 3" className="w-20 h-20" />
          <img src={logo4} alt="Client 4" className="w-20 h-20" />
          <img src={logo5} alt="Client 5" className="w-20 h-20" />
          <img src={logo6} alt="Client 6" className="w-20 h-20" />
          <img src={logo7} alt="Client 7" className="w-20 h-20" />
          <img src={logo8} alt="Client 8" className="w-20 h-20" />
        </div>
      </section>

      {/* Key Features Section */}
      <section className="py-16 bg-black text-white grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">
        <div className="flex flex-col justify-center text-center md:text-left">
          <h3 className="text-3xl font-semibold text-yellow-400 mb-4">
            Effortless Booking
          </h3>
          <p className="text-lg text-gray-300">
            Manage bookings with ease using our real-time tracking and flexible
            tools.
          </p>
        </div>
        <img src={booking} alt="Booking" className="rounded-lg shadow-lg" />

        <img src={support} alt="Support" className="rounded-lg shadow-lg" />
        <div className="flex flex-col justify-center text-center md:text-left">
          <h3 className="text-3xl font-semibold text-yellow-400 mb-4">
            24/7 Customer Support
          </h3>
          <p className="text-lg text-gray-300">
            Our dedicated team is available around the clock to assist with all
            your needs.
          </p>
        </div>
      </section>

      {/* Global Network Section */}
      <section className="py-20 bg-gradient-to-r from-black to-gray-800 text-center">
        <img src={global} alt="Global Network" className="h-72 mx-auto mb-8" />
        <h2 className="text-4xl font-bold text-yellow-400 mb-4">
          Global Network
        </h2>
        <p className="text-lg text-gray-300 max-w-2xl mx-auto">
          Access our network of trusted partners for smooth, efficient travel
          experiences worldwide.
        </p>
      </section>

      {/* FAQ Section */}
      <section className="py-16 bg-gray-900 text-white">
        <h2 className="text-3xl font-bold text-center mb-8 text-yellow-400">
          Frequently Asked Questions
        </h2>
        <div className="max-w-3xl mx-auto">
          <div className="space-y-4">
            {faqData.map((faq, index) => (
              <details key={index} className="bg-gray-800 p-4 rounded-lg">
                <summary className="font-semibold cursor-pointer">
                  {faq.question}
                </summary>
                <p className="mt-2 text-gray-300">{faq.answer}</p>
              </details>
            ))}
          </div>
        </div>
      </section>

      {/* Customer Testimonials */}
      <section className="py-16 bg-black text-white text-center">
        <h2 className="text-3xl font-bold text-yellow-400 mb-8">
          What Our Clients Say
        </h2>
        <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-gray-800 p-6 rounded-lg shadow-md">
            <p className="text-xl mb-4 text-gray-300">
              "Excellent platform for corporate travel management. Our go-to
              solution!"
            </p>
            <p className="font-semibold text-yellow-400">
              - Alex P., Company XYZ
            </p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-md">
            <p className="text-xl mb-4 text-gray-300">
              "User-friendly, efficient, and great customer support!"
            </p>
            <p className="font-semibold text-yellow-400">
              - Maya T., Corp Alpha
            </p>
          </div>
        </div>
      </section>

      {/* Additional Sections */}
      <section className="py-20 bg-gray-900 text-center text-white">
        <h2 className="text-4xl font-bold text-yellow-400 mb-6">
          API Integrations
        </h2>
        <p className="text-lg max-w-3xl mx-auto mb-6 text-gray-300">
          Fast and simple API integration options to power your business with
          our booking solutions.
        </p>
        <button
          onClick={handleLearnMoreClick}
          className="bg-yellow-500 hover:bg-yellow-600 transition-colors text-black px-6 py-3 rounded-lg font-semibold"
        >
          Learn More
        </button>
      </section>

      <section className="py-20 bg-black text-center text-white">
        <h2 className="text-4xl font-bold text-yellow-400 mb-6">Our Awards</h2>
        <p className="text-lg max-w-3xl mx-auto mb-6 text-gray-300">
          Recognized in global travel industry awards for innovation and service
          excellence.
        </p>
        <div className="text-yellow-400 font-semibold">
          TRIPDREAM TRAVEL AWARDS 2022, GLOBE TRAVEL AWARDS 2024
        </div>
      </section>
    </div>
  );
};

export default Home;
