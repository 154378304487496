import React from "react";
import diversity from "assests/diversity.webp";
import journey from "assests/journey.png";
import mission from "assests/mission.jpg";
import logo1 from "assests/mongodb-logo.svg";
import logo2 from "assests/yelp_logo.webp";
import logo3 from "assests/shaw-floors-vector.svg";
import logo4 from "assests/nilfisk-logo.webp";
import logo5 from "assests/dd_logo.svg";
import logo6 from "assests/snowflake-logo.svg";
import logo7 from "assests/thomson-reuters-logo-vector.svg";
import logo8 from "assests/box-logo-blue.svg";
import { useNavigate } from "react-router-dom";

/**
 * AboutUs component
 * @returns {jsx}
 */
const AboutUs = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact");
  };

  return (
    <div className="font-serif bg-black text-white">
      <section className="container mx-auto px-4 py-12 text-white bg-black">
        {/* Основной заголовок */}
        <h1 className="text-5xl font-extrabold text-center text-yellow-400 mb-16">
          About itllte
        </h1>

        {/* Секция миссии */}
        <div className="mb-16 flex flex-col lg:flex-row items-center">
          <div className="flex-1 text-center lg:text-left lg:pr-8 mb-8 lg:mb-0">
            <h2 className="text-4xl font-bold text-yellow-400 mb-4">
              Our Mission
            </h2>
            <p className="text-lg text-gray-200 mb-6">
              At itllte, we are dedicated to redefining business travel with a
              seamless booking experience, comprehensive expense management, and
              innovative tools to meet the demands of modern companies
              worldwide.
            </p>
            <ul className="list-disc list-inside text-lg text-gray-300 space-y-4">
              <li>Fostering valuable human connections through travel</li>
              <li>Empowering businesses with data-driven decisions</li>
              <li>Delivering high-performance solutions for dynamic needs</li>
            </ul>
          </div>
          <div className="flex-1">
            <img
              src={mission}
              alt="Our mission"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>

        {/* Секция истории компании */}
        <div className="mb-16 flex flex-col lg:flex-row-reverse items-center">
          <div className="flex-1 text-center lg:text-left lg:pl-8 mb-8 lg:mb-0">
            <h2 className="text-4xl font-bold text-yellow-400 mb-4">
              Our Journey
            </h2>
            <p className="text-lg text-gray-200 mb-6">
              Since 2015, itllte has grown from a user-centered platform to a
              comprehensive solution with global reach, leveraging AI and data
              science to reshape the business travel industry.
            </p>
            <ul className="list-disc list-inside text-lg text-gray-300 space-y-4">
              <li>2015: Founded with a focus on seamless travel management</li>
              <li>2021: Strategic acquisitions for global expansion</li>
              <li>2023: Entered new markets with a broadened offering</li>
            </ul>
          </div>
          <div className="flex-1">
            <img
              src={journey}
              alt="Our journey"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>

        {/* Секция разнообразия, равенства и инклюзивности */}
        <div className="flex flex-col lg:flex-row items-center">
          <div className="flex-1 text-center lg:text-left lg:pr-8 mb-8 lg:mb-0">
            <h2 className="text-4xl font-bold text-yellow-400 mb-4">
              Diversity, Equity & Inclusion
            </h2>
            <p className="text-lg text-gray-200 mb-6">
              At itllte, we see diversity as a strength. We are committed to
              creating an inclusive environment that fosters growth,
              collaboration, and mutual respect among our team and partners.
            </p>
            <button
              onClick={handleClick}
              className="bg-yellow-400 text-black px-6 py-3 rounded-lg text-lg font-semibold hover:bg-yellow-500 transition duration-300"
            >
              Learn More
            </button>
          </div>
          <div className="flex-1">
            <img
              src={diversity}
              alt="Diversity and inclusion"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>

        {/* Раздел с логотипами партнеров */}
        <div className="my-16 text-center">
          <h3 className="text-3xl font-bold text-yellow-400 mb-8">
            Trusted by Global Brands
          </h3>
          <div className="flex flex-wrap justify-center items-center gap-6">
            <img src={logo1} alt="MongoDB logo" className="h-12" />
            <img src={logo2} alt="Yelp logo" className="h-12" />
            <img src={logo3} alt="Shaw Floors logo" className="h-12" />
            <img src={logo4} alt="Nilfisk logo" className="h-12" />
            <img src={logo5} alt="DD logo" className="h-12" />
            <img src={logo6} alt="Snowflake logo" className="h-12" />
            <img src={logo7} alt="Thomson Reuters logo" className="h-12" />
            <img src={logo8} alt="Box logo" className="h-12" />
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center mt-12">
          <button
            onClick={handleClick}
            className="bg-yellow-400 text-black px-8 py-4 rounded-lg text-lg font-semibold hover:bg-yellow-500 transition duration-300"
          >
            Join Us and Discover Where itllte Can Take You
          </button>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
