import { useState } from "react";
import { Link } from "react-router-dom";
import Toast from "components/ux/toast/Toast";

/**
 * ForgotPassword component responsible for handling the forgot password form.
 * @returns {jsx}
 */
const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  /**
   * Handles input changes for the login form fields.
   * Updates the loginData state with the field values.
   * @param {Object} e - The event object from the input field.
   */
  const handleInputChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const dismissError = () => {
    setErrorMessage("");
  };

  /**
   * Handles the submission of the login form.
   * Attempts to authenticate the user with the provided credentials.
   * Navigates to the user profile on successful login or sets an error message on failure.
   * @param {Object} e - The event object from the form submission.
   */
  const handleForgotSubmit = async (e) => {
    e.preventDefault();

    // Simulate a successful email submission
    if (loginData.email) {
      setSuccess(true);
      setErrorMessage("");
    } else {
      setErrorMessage("Invalid email.");
    }
  };

  return (
    <>
      <div className="bg-black min-h-screen flex items-center justify-center">
        <div className="container mx-auto p-4 flex justify-center">
          {success ? (
            <div className="bg-gray-800 p-6 md:mx-auto rounded-lg shadow-lg">
              <svg
                viewBox="0 0 24 24"
                className="text-yellow-400 w-16 h-16 mx-auto my-6"
              >
                <path
                  fill="currentColor"
                  d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                ></path>
              </svg>
              <div className="text-center">
                <h3 className="md:text-2xl text-base text-white font-semibold text-center">
                  Recovery Email has been sent!
                </h3>
                <p className="text-yellow-400">
                  Don't forget to check your spam
                </p>
                <div className="my-6 text-center">
                  <Link
                    to="/"
                    className="px-12 bg-yellow-400 hover:bg-yellow-500 text-black font-semibold py-3 rounded"
                  >
                    GO BACK
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <form
              onSubmit={handleForgotSubmit}
              className="w-full max-w-lg p-6 md:p-10 shadow-md bg-gray-800 rounded-lg"
            >
              <div className="text-center mb-10">
                <h2 className="text-3xl font-extrabold text-yellow-400 my-4">
                  Reset your password
                </h2>
                <div className="mb-6">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={loginData.email}
                    onChange={handleInputChange}
                    autoComplete="username"
                    className="appearance-none block w-full bg-gray-700 text-gray-300 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  />
                  <p className="text-gray-300">
                    We’ll send a verification code to this email if it matches
                    an existing account.
                  </p>
                </div>
                {errorMessage && (
                  <Toast
                    type="error"
                    message={errorMessage}
                    dismissError={dismissError}
                  />
                )}
                <div className="flex-wrap items-center justify-between">
                  <button
                    type="submit"
                    className="w-full bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Reset your password
                  </button>
                  <div className="mt-5">
                    <Link
                      to="/login"
                      className="inline-block align-baseline text-lg text-gray-500 hover:text-yellow-400 text-right"
                    >
                      Back to login
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
